import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


let token = localStorage.getItem('token') || null;
let token_driver = localStorage.getItem('token_driver')  || null;
let token_customer = localStorage.getItem('token_customer')  || null;

const routes = [
  {
    path: '/admin',
    name: 'admin',
    meta: { requiresAuth: true },
    component: () => import('../views/templates/Admin.vue'),
    children: [

      {
        path: 'areasCustomers',
        name: 'areasCustomers',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/areas_customers/index.vue'),
      },

      
      {
        path: 'importSubscribes',
        name: 'importSubscribes',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/import_subscribes/index.vue'),
      },

      {
        path: 'reportPage',
        name: 'reportPage',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/report/report.vue'),
      },

      {
        path: 'approveReports',
        name: 'approveReports',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/report/approve.vue'),
      },

      {
        path: 'home',
        name: 'home',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/home/HomePage.vue'),
      },
      {
        path: 'noPermission',
        name: 'noPermission',
        meta: { requiresAuth: false },
        component: () => import('../views/pages/home/NoPermission.vue'),
      },
      {
        path: 'division',
        name: 'division',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/division/index.vue'),
      },
      {
        path: 'complaint_division',
        name: 'complaint_division',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/complaint_sections/index.vue'),
      },
      {
        path: 'packaging_group',
        name: 'packaging_group',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/packaging_groups/index.vue'),
      },
      {
        path: 'packaging_group_city_period',
        name: 'packaging_group_city_period',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/packaging_group_city_periods/index.vue'),
      },
      {
        path: 'complaint_type',
        name: 'complaint_type',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/complaint_types/index.vue'),
      },
      {
        path: 'group',
        name: 'group',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/group/index.vue'),
      },

      {
        path: 'nutrition_fact',
        name: 'nutrition_fact',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/nutrition_fact/index.vue'),
      },
      {
        path: 'cuisine',
        name: 'cuisine',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/cuisine/index.vue'),
      },
      {
        path: 'tool',
        name: 'tool',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/tool/index.vue'),
      },
      {
        path: 'unit',
        name: 'unit',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/unit/index.vue'),
      },
      {
        path: 'country',
        name: 'country',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/country/index.vue'),
      },
      {
        path: 'city',
        name: 'city',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/city/index.vue'),
      },
      {
        path: 'branch',
        name: 'branch',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/branch/index.vue'),
      },
      {
        path: 'period',
        name: 'period',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/period/index.vue'),
      },
      {
        path: 'bank_name',
        name: 'bank_name',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/bank_name/index.vue'),
      },
      {
        path: 'company',
        name: 'company',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/company/index.vue'),
      },
      {
        path: 'group_name',
        name: 'group_name',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/group_name/index.vue'),
      },
      {
        path: 'package',
        name: 'package',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/package/index.vue'),
      },
      {
        path: 'package/addEdit/:id?',
        name: 'packageAddEdit',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/package/addEdit.vue'),
      },


      {
        path: 'ingredient',
        name: 'ingredient',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/resturant/ingredient/index.vue'),
      },
      {
        path: 'ingredient/addEdit/:id?',
        name: 'ingredientaddEdit',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/resturant/ingredient/addEdit.vue'),
      },

      {
        path: 'recipie',
        name: 'recipie',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/resturant/recipie/index.vue'),
      },
      {
        path: 'recipie/addEdit/:id?',
        name: 'recipieaddEdit',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/resturant/recipie/addEdit.vue'),
      },

      {
        path: 'menu',
        name: 'menu',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/resturant/menu/index.vue'),
      },
      {
        path: 'menu/addEdit/:id?',
        name: 'menuaddEdit',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/resturant/menu/addEdit.vue'),
      },


      {
        path: 'subscribe',
        name: 'subscribe',
        meta: { requiresAuth: true },
        deep: true,
        component: () => import('../views/pages/sale/subscribe/index.vue'),
      },

      // {
      //   path: 'resumeSubscribe',
      //   name: 'resumeSubscribe',
      //   meta: { requiresAuth: true },
      //   component: () => import('../views/pages/sale/subscribe/resume.vue'),
      // },

      
      {
        path: 'subscribe/addEdit/:id?',
        name: 'subscribeaddEdit',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/sale/subscribe/addEdit.vue'),
      },

      {
        path: 'subscribe/addDuplicate/:id?',
        name: 'subscribeaddDuplicate',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/sale/subscribe/addDuplicate.vue'),
      },

      {
        path: 'subscribe/details/:id?',
        name: 'subscribeDetails',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/sale/subscribe/details.vue'),
      },

      {
        path: 'user',
        name: 'user',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/user/index.vue'),
      },
      {
        path: 'userAddEdit/:id?',
        name: 'userAddEdit',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/user/addEdit.vue'),
      },

      {
        path: 'user_permission',
        name: 'userPermission',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/user_permission/index.vue'),
      },

      {
        path: 'user_permission/addEdit/:id?',
        name: 'userPermissionAddEdit',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/user_permission/addEdit.vue'),
      },

      {
        path: 'activities',
        name: 'activities',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/activities/index.vue'),
      },

      {
        path: 'drivers',
        name: 'drivers',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/drivers/index.vue'),
      },

      {
        path: 'drivers/details/:id?',
        name: 'driverDetails',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/drivers/details.vue'),
      },

      {
        path: 'customers',
        name: 'customers',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/customers/index.vue'),
      },

      {
        path: 'customers/details/:id?',
        name: 'customerDetails',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/customers/details.vue'),
      },




    ],
  },

  
  {
    path: '/landing',
    name: 'landing',
    component: () => import('../views/templates/Landing.vue'),
    redirect: { name: 'customer_login' },
    children: [
      {
        path: 'login',
        name: 'login',
        meta: { requiresAuth: false },
        component: () => import('../views/pages/login/LoginPage.vue')
      },
      {
        path: 'customer_login',
        name: 'customer_login',
        meta: { requiresCustomerAuth: false },
        component: () => import('../views/pages/customer/login/LoginPage.vue')
      },
      {
        path: 'customer_register',
        name: 'customer_register',
        meta: { requiresCustomerAuth: false },
        component: () => import('../views/pages/customer/register/RegisterPage.vue')
      },
      {
        path: 'reset_password',
        name: 'reset_password',
        meta: { requiresCustomerAuth: false },
        component: () => import('../views/pages/customer/login/ResetPasswordPage.vue')
      },
    ],
  },



  {
    path: '/reports',
    name: 'reports',
    meta: { requiresAuth: true },
    component: () => import('../views/templates/Reports.vue'),
    children: [
      {
        path: 'areasCustomers',
        name: 'areasCustomersReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/areas_customers/report.vue'),
      },
      {
        path: 'division',
        name: 'divisionReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/division/report.vue'),
      },
      {
        path: 'complaint_division',
        name: 'complaint_division_report',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/complaint_sections/report.vue'),
      },
      {
        path: 'packaging_group',
        name: 'packaging_group_report',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/packaging_groups/report.vue'),
      },
      {
        path: 'packaging_group_city_period',
        name: 'packaging_group_city_period_report',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/packaging_group_city_periods/report.vue'),
      },
      {
        path: 'complaint_type',
        name: 'complaint_type_report',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/complaint_types/report.vue'),
      },
      {
        path: 'group',
        name: 'groupReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/group/report.vue'),
      },
      {
        path: 'nutritionFact',
        name: 'nutritionFactReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/nutrition_fact/report.vue'),
      },
      {
        path: 'cuisine',
        name: 'cuisineReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/cuisine/report.vue'),
      },
      {
        path: 'tool',
        name: 'toolReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/tool/report.vue'),
      },
      {
        path: 'unit',
        name: 'unitReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/unit/report.vue'),
      },
      {
        path: 'country',
        name: 'countryReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/country/report.vue'),
      },
      {
        path: 'city',
        name: 'cityReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/city/report.vue'),
      },
      {
        path: 'branch',
        name: 'branchReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/branch/report.vue'),
      },
      {
        path: 'period',
        name: 'periodReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/period/report.vue'),
      },
      {
        path: 'bankName',
        name: 'bankNameReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/bank_name/report.vue'),
      },
      {
        path: 'company',
        name: 'companyReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/company/report.vue'),
      },
      {
        path: 'groupName',
        name: 'groupNameReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/group_name/report.vue'),
      },
      {
        path: 'package',
        name: 'packageReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/package/report.vue'),
      },

      {
        path: 'ingredient',
        name: 'ingredientReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/resturant/ingredient/report.vue'),
      },
      {
        path: 'recipie',
        name: 'recipieReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/resturant/recipie/report.vue'),
      },
      {
        path: 'menu',
        name: 'menuReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/resturant/menu/report.vue'),
      },

      {
        path: 'subscribe',
        name: 'subscribeReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/sale/subscribe/report.vue'),
      },

      {
        path: 'user',
        name: 'userReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/user/report.vue'),
      },


      {
        path: 'cookingToday',
        name: 'cookingTodayReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/report/cooking_today/report.vue'),
      },

      {
        path: 'delegateToday',
        name: 'delegateTodayReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/report/delegate_today/report.vue'),
      },
      {
        path: 'quantitiesToday',
        name: 'quantitiesTodayReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/report/quantities_today/report.vue'),
      },


      
    ],
  },


  {
    path: '/customer',
    name: 'customer',
    meta: { requiresCustomerAuth: true },
    redirect: { name: 'customerHome' },
    component: () => import('../views/templates/Customer.vue'),
    children: [
      {
        path: 'home',
        name: 'customerHome',
        meta: { requiresCustomerAuth: true },
        component: () => import('../views/pages/customer/home/HomePage.vue'),
      },
      {
        path: 'subscriptions',
        name: 'customerSubscriptions',
        meta: { requiresCustomerAuth: true },
        component: () => import('../views/pages/customer/subscription/index.vue'),
      },
      {
        path: 'subscriptions/details/:id',
        name: 'customerSubscriptionDetails',
        meta: { requiresCustomerAuth: true },
        component: () => import('../views/pages/customer/subscription/details.vue'),
      },
    ]
  },


  {
    path: '/driver',
    name: 'driver',
    meta: { requiresDriverAuth: true },
    redirect: { name: 'driverHome' },
    component: () => import('../views/templates/Driver.vue'),
    children: [
      {
        path: 'home',
        name: 'driverHome',
        meta: { requiresDriverAuth: true },
        component: () => import('../views/pages/driver/home/HomePage.vue'),
      },
      {
        path: 'home/event-details/:id',
        name: 'eventDriverDetails',
        meta: { requiresDriverAuth: true },
        component: () => import('../views/pages/driver/home/AdminEventDetails.vue'),
      },

      {
        path: 'events/:id/:id2',
        name: 'driverEvents',
        meta: { requiresDriverAuth: true },
        component: () => import('../views/pages/driver/events/index.vue'),
      },
      {
        path: 'eventsAll/:id/:id2',
        name: 'driverEvents2',
        meta: { requiresDriverAuth: true },
        component: () => import('../views/pages/driver/events/index.vue'),
      },
      {
        path: 'sortEvents/:id/:id2',
        name: 'sortEvents',
        meta: { requiresDriverAuth: true },
        component: () => import('../views/pages/driver/events/sort.vue'),
      },
      {
        path: 'sortEventsAll/:id/:id2',
        name: 'sortEvents2',
        meta: { requiresDriverAuth: true },
        component: () => import('../views/pages/driver/events/sort.vue'),
      },
      {
        path: 'checkedEvents/:id/:id2',
        name: 'checkedEvents',
        meta: { requiresDriverAuth: true },
        component: () => import('../views/pages/driver/events/checked.vue'),
      },
      {
        path: 'checkedEventsAll/:id/:id2',
        name: 'checkedEvents2',
        meta: { requiresDriverAuth: true },
        component: () => import('../views/pages/driver/events/checked.vue'),
      },
      {
        path: 'details/:id/:id2',
        name: 'driverEventsDetails',
        meta: { requiresDriverAuth: true },
        component: () => import('../views/pages/driver/events/details.vue'),
      },


    ],
  },
  
  {
    path: '/',
    redirect: { name: 'customer_login' },
  },

]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name == 'login' && token) {
    next({ name: 'home' });
  } else if (to.name == 'login' && token_driver && !token && !token_customer) {
    next({ name: 'driverHome' });
  }
  else if (to.name == 'login' && token_customer && !token && !token_driver) {
    next({ name: 'customerHome' });
  } else if (to.name == 'customer_login' && token_customer && !token && !token_driver) {
    next({ name: 'customerHome' });
  } else if (to.name == 'customer_login' && !token_customer && token && !token_driver) {
    next({ name: 'home' });
  } else if (to.name == 'customer_login' && !token_customer && !token && token_driver) {
    next({ name: 'driverHome' });
  } else if (to.name == 'reset_password' && !token_customer && !token && !token_driver) {
    next();
  }
  else if (to.name == 'customer_register' && !token_customer && !token && !token_driver) {
  next();
  }
  else if (to.name != 'login' && to.name != 'customer_login' && !token && !token_driver && !token_customer) {
    next({ name: 'login' });
  } 
  else {
    next();
  }
});



export default router
