import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import config from './config'
import vuetify from './plugins/vuetify'
import mixin from "./mixin";
import i18n from '@/language/lang'
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);
Vue.mixin(mixin);

Vue.config.productionTip = false

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import elementAr from "element-ui/lib/locale/lang/ar";
import locale from 'element-ui/lib/locale'
Vue.use(ElementUI, { elementAr });
locale.use(elementAr)

import BlockUI from 'vue-blockui'
Vue.use(BlockUI)


Vue.config.disableNoTranslationWarning = true;


import notify from 'element-ui/lib/notification'

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import './assets/scss/index.scss'


import axios from 'axios';
window.axios = axios.create({ 
  baseURL: config.hostUrl,
});

window.axios.defaults.headers.lang = config.lang;
if (config.token) {
  window.axios.defaults.headers.common = { 
    'Authorization': `Bearer ${config.token}`,
    'language': config.lang
  }
}

else if (config.token_driver) {
  window.axios.defaults.headers.common = {
    'headers': {
      "Access-Control-Allow-Origin": "*",
      'Content-Type': 'application/json;',
    },
    'Authorization': `Bearer ${config.token_driver}`,
    'language': config.lang
  }
}
else if (config.token_customer) {
  window.axios.defaults.headers.common = {
    'headers': {
      "Access-Control-Allow-Origin": "*",
      'Content-Type': 'application/json;',
    },
    'Authorization': `Bearer ${config.token_customer}`,
    'language': config.lang
  }
}


else {
    window.axios.defaults.headers.common = { 
      'language': config.lang,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
  }
}

window.axios.interceptors.response.use(function (res) {
  return res;
}, function (result) {
  if (result && result.response && result.response.status == 500) { 
    notify.error({
      title: i18n.t('Error'),
      message: 'Error Happened',
      duration: 3000,
    });
  }
  if(result && result.response && result.response.data &&  result.response.data.data) {
    notify.error({
      title: i18n.t('Error'),
      message: result.response.data.data,
      duration: 3000,
    });
  }
  else if(result && result.response && result.response.data &&  result.response.data.errors) {
    notify.error({
      title: i18n.t('Error'),
      message: result.response.data.errors,
      duration: 3000,
    });
  }
  else if(result && result.response && result.response.data &&  result.response.data.message) {
    notify.error({
      title: i18n.t('Error'),
      message: result.response.data.message,
      duration: 3000,
    });
  }
  else if (result && result.response && result.response.status == 403) { 
    router.push({name: 'noPermission'})
  }


  if(result && result.response && result.response.data && result.response.data.errors && result.response.data.errors.first_login ) {
    router.push({name: 'reset_password'})
  }
  if (result && result.response && result.response.data.includes('Route [login] not defined')) { 
    // server error
    mixin.methods.logoutSuccess();  /// neeed to return it 
  }

  
  return Promise.reject(result);
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  mixins: [mixin],
  render: function (h) { return h(App) }
}).$mount('#app')
