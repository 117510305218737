let route = '/api/admin/';
let crud = 'units';
const actions = {

  getData({ }, queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}`, {
        params: queryParams
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  listAll({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_all`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  

  findData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  saveData({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateData({ }, data) {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      for (let key in data) {
        if (key == 'imageLink') {
          form.append('image', data['imageLink']);
        }
        delete data['image'];
      }
      if (data.name.ar) form.append('name[ar]', data.name.ar);
      if (data.name.en) form.append('name[en]', data.name.en);
      axios.post(`${route}${crud}/${data.id}`, form)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateStatus({ }, id) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/${id}/update_status`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${route}${crud}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

}
export default {
  namespaced: true,
  actions,
}
