const actions = {
  getData({ }, inputs) {

    return new Promise((resolve, reject) => {
      axios.get(`/api/driver/home/get_subscription/${inputs.branch_id}/${inputs.period_id}?date=${inputs.date}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getData2({ }, inputs) {

    return new Promise((resolve, reject) => {
      axios.get(`/api/driver/home/get_all_subscription/${inputs.city_id}/${inputs.period_id}?date=${inputs.date}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateSubscriptionOrder({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/driver/home/update_subscription_order/${data.subscription_id}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateSubscriptionOrder2({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/driver/home/update_subscription_order/${data.id}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

}
export default {
  namespaced: true,
  actions,
}
