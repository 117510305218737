let route = '/api/admin/';
let crud = 'activities';
const actions = {

  getData({ }, queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}`, {
        params: queryParams
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  listTypes({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_types`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  listAdmins({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_admins`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  actions,
}
