var mixin = {
  methods: {
    updateStatusData(statusRequest, id) {
      return this.$store
        .dispatch(statusRequest, id)
        .then((res) => {
          return this.notifySuccess(`UpdateStatus`, res);
        });
    },
    deleteData(deleteRequest, id) {
      return this.$store
        .dispatch(deleteRequest, id)
        .then((res) => {
          return this.notifySuccess(`Delete`, res);
        });
    },
    saveUpdateData(saveRequest, updateRequest, formData) {
        if (formData.id > 0) {
          return this.$store
            .dispatch(updateRequest, formData)
            .then((res) => {
              return this.notifySuccess(`Update`, res);
            });
        } else {
          return this.$store
            .dispatch(saveRequest, formData)
            .then((res) => {
              return this.notifySuccess(`Save`, res);
            });
        }
    },
    getFrontEndData(apiRequest, options, filters) {
      options ? options = Object.assign(options, filters) : options = filters;
      return this.$store
        .dispatch(apiRequest, options)
        .then((res) => {
          return res.data;
        })
    },
    getBackEndData(apiRequest, options) {
      return this.$store
        .dispatch(apiRequest, options)
        .then((res) => {
          return res.data;
        })
    },
    notifyCustomError(title, error) {
      this.$notify.error({
        title: this.$t(title),
        message: this.$t(error),
        duration: 3000,
      });
    },
    notifyError(title, result) {

      let message= "";
      if(result && result.response && result.response.data && result.response.data.message){
        message= result.response.data.message
      }
      else if (result && result.response && result.response.data && result.response.data.errors){
        message= result.response.data.errors
      }
      this.$notify.error({
        title: this.$t(title),
        message: message,
        duration: 3000,
      });
    },
    notifySuccess(title, result) {
      this.$notify.success({
        title: this.$t(title),
        message: result.message,
        duration: 3000,
      });
    },
    customNotifySuccess(title, msg) {
      this.$notify.success({
        title: title,
        message: msg,
        duration: 3000,
      });
    },
    customNotifyError(title, msg) {
      this.$notify.error({
        title: title,
        message: msg,
        duration: 3000,
      });
    },
    loginCustomerSuccess(result) {
      localStorage.setItem("customer", JSON.stringify(result.data.user));
      localStorage.setItem("token_customer", result.data.token);
      window.location.href="/";
    },
    loginSuccess(result) {
      localStorage.setItem("user", JSON.stringify(result.data.user));
      localStorage.setItem("token", result.data.token);
      window.location.href="/";
    },
    logoutSuccess() {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('driver');
      localStorage.removeItem('token_driver');
      localStorage.removeItem('token_customer');
      window.location.href="/landing/login";
    },
    loginDriverSuccess(result) {
      localStorage.setItem("driver", JSON.stringify(result.data.user));
      localStorage.setItem("token_driver", result.data.token);
      window.location.href="/";
    },
    logoutDriverSuccess() {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('driver');
      localStorage.removeItem('token_driver');
      localStorage.removeItem('token_customer');
      window.location.href="/landing/login";
    },
    logoutCustomerSuccess() {
      localStorage.removeItem('customer');
      localStorage.removeItem('token_customer');
      localStorage.removeItem('driver');
      localStorage.removeItem('token_driver');
      localStorage.removeItem('token_customer');
      window.location.href="/landing/customer_login";
    }
  }
}
export default mixin;