const actions = {

  getCustomerData({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/customer-api/home`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSubscriptionsData({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/customer-api/subscriptions`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  findData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/customer-api/get_subscription/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchCookingDailyWithDetails( {} , queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/customer-api/${queryParams.id}/fetch_cooking_daily_with_details`,
        {
          params: queryParams
        } 
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  stopSubscribe({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/customer-api/subscribe/stop_sub/${data.id}`, data.formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  startSubscribe({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/customer-api/give_sub/${data.id}`, data.formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


}
export default {
  namespaced: true,
  actions,
}
